<template>
  <div
    ref="cardContainerRef"
    class="relative mx-auto -mt-12 w-full max-w-8xl"
    :class="$attrs.class"
  >
    <!-- Card container -->
    <div
      class="card-container relative w-full rounded-[15px] border-none bg-background transition-colors duration-400 group p-6"
      :style="{ 
        padding: `${borderThickness}px`,
        aspectRatio: fitContent ? 'auto' : aspectRatio
      }"
      @mouseenter="handleMouseEnter"
      @mouseleave="handleMouseLeave"
      @mousemove="handleMouseMove"
    >
      <!-- Base beam effect container -->
      <div 
        class="beam-effect absolute inset-0 rounded-[15px] pointer-events-none"
        :style="beamStyle"
      />
      
      <!-- Blurred glow beam effect -->
      <div 
        class="beam-effect absolute inset-0 rounded-[15px] pointer-events-none blur-xl"
        :style="glowBeamStyle"
      />

      <div
        class="relative z-[2] size-full overflow-hidden rounded-[12px] md:rounded-[12px]"
        :class="[background, {'aspect-container': !fitContent}]"
      >
        <!-- Add the blob component -->
        <AnimatedSvgBlob 
          v-if="showBlob"
          :speed="blobSpeed"
          class="absolute inset-0 w-full h-full"
        />
        
        <!-- Wrap slot in a relative container to stay above blob -->
        <div class="relative z-10 size-full p-6">
          <slot></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useColorMode } from '@vueuse/core'
import AnimatedSvgBlob from './AnimatedSvgBlob.vue'

const props = defineProps({
  beamWidth: {
    type: Number,
    default: 48 // Default width percentage where gradient becomes transparent
  },
  borderThickness: {
    type: Number,
    default: 8 // Default border thickness in pixels
  },
  background: {
    type: String,
    default: 'bg-gradient-to-b from-zinc-100 to-neutral-200 dark:from-zinc-900 dark:to-neutral-800'
  },
  showBlob: {
    type: Boolean,
    default: false
  },
  blobSpeed: {
    type: Number,
    default: 0.5
  },
  aspectRatio: {
    type: String,
    default: '16/9'
  },
  fitContent: {
    type: Boolean,
    default: false
  }
});

// Beam effect logic
const cardContainerRef = ref<HTMLDivElement | null>(null);
const mouse = ref<{ x: number; y: number }>({ x: 0, y: 0 });
const radius = ref(0);
const opacity = ref(0);

const colorMode = useColorMode()

const beamStyle = computed(() => {
  const baseOpacity = colorMode.value === 'dark' ? 0.25 : 0.35;
  const step = props.beamWidth / 6;
  
  return {
    background: `
      radial-gradient(
        ${radius.value}px circle at ${mouse.value.x}px ${mouse.value.y}px,
        rgba(255, 0, 255, ${baseOpacity * opacity.value}) 0%,
        rgba(255, 0, 128, ${baseOpacity * opacity.value}) ${step}%,
        rgba(255, 0, 0, ${baseOpacity * opacity.value}) ${step * 2}%,
        rgba(255, 128, 0, ${baseOpacity * opacity.value}) ${step * 3}%,
        rgba(0, 255, 255, ${baseOpacity * opacity.value}) ${step * 4}%,
        rgba(128, 0, 255, ${baseOpacity * opacity.value}) ${step * 5}%,
        transparent ${props.beamWidth}%
      )`,
  };
});

const glowBeamStyle = computed(() => {
  const glowOpacity = colorMode.value === 'dark' ? 0.1 : 0.1;
  const step = props.beamWidth / 6;
  
  return {
    background: `
      radial-gradient(
        ${radius.value}px circle at ${mouse.value.x}px ${mouse.value.y}px,
        rgba(255, 50, 255, ${glowOpacity * opacity.value}) 0%,
        rgba(255, 50, 150, ${glowOpacity * opacity.value}) ${step}%,
        rgba(255, 50, 50, ${glowOpacity * opacity.value}) ${step * 2}%,
        rgba(255, 150, 50, ${glowOpacity * opacity.value}) ${step * 3}%,
        rgba(50, 255, 255, ${glowOpacity * opacity.value}) ${step * 4}%,
        rgba(150, 50, 255, ${glowOpacity * opacity.value}) ${step * 5}%,
        transparent ${props.beamWidth}%
      )`,
    mixBlendMode: colorMode.value === 'dark' ? 'normal' : 'screen',
  };
});

// Create a GSAP timeline for managing animations
const beamTimeline = useGsap.timeline({ paused: true });

onMounted(() => {
  // Set up the timeline animations
  beamTimeline
    .to(radius, {
      value: 800,
      duration: 0.5,
      ease: 'power2.out'
    })
    .to(opacity, {
      value: 1,
      duration: 0.5,
      ease: 'power2.out'
    }, '<');
});

function handleMouseEnter() {
  beamTimeline.play();
}

function handleMouseLeave() {
  beamTimeline.reverse();
}

function handleMouseMove({ clientX, clientY }: MouseEvent) {
  if (!cardContainerRef.value) return;
  const { left, top } = cardContainerRef.value.getBoundingClientRect();
  mouse.value = { x: clientX - left, y: clientY - top };
}
</script>

<style scoped>
.beam-effect {
  transition: background-position 0.2s ease;
}

.blur-xl {
  filter: blur(24px);
  transform: scale(1.1);
}

.aspect-container {
  position: relative;
  height: 100%;
  width: 100%;
}

.aspect-container > :deep(img) {
  position: absolute;
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
}
</style>